//Header.js
import React, { useEffect, useState } from 'react';
import logo from '../images/logo.png';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useWalletClient } from "wagmi";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: signer } = useWalletClient();
  const { address, isConnected } = useAccount();
  const [provider, setProvider] = useState("");

  useEffect(() => {
    if (signer) {
      setProvider(signer);
    }
  }, [signer, isConnected]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className={!signer ? "header" : "header connected"}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header__content">
                <button className={`header__btn ${isOpen ? 'header__btn--active' : ''}`} type="button" aria-label="header__nav" onClick={toggleMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div className="header__logo">
                    <img src={logo} alt="ElmoStake Logo" />
                    <ul className={`header__nav ${isOpen ? 'header__nav--active' : ''}`} id="header__nav">
                      <li>
                        <a target='_blank'  rel="noreferrer" href="https://froggiestoken.com">Website</a>
                      </li>
                      <li>
                        <a target='_blank'  rel="noreferrer" href="https://pancakeswap.finance/swap?outputCurrency=0x440758dF68A045DB3F2517257F27330a12438656">Buy $FRGST</a>
                      </li>
                      <li>
                        <a target='_blank'  rel="noreferrer" href="https://skynet.certik.com/projects/froggies-token">Certik Audit</a>
                      </li>
                    </ul>
                    <div className='connect'>
                      <ConnectButton chainStatus="none" showBalance={false} label='Connect' />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;