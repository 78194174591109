//App.js
import { useRef, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import DateTimeDisplay from './components/DateTimeDisplay';
import Countdown from "react-countdown";
import moment from "moment/moment";
import Header from "./components/Header";
import StakeForm from "./components/StakeForm";
import { useAccount, useDisconnect } from 'wagmi';


function App() {
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect()
  const myRef = useRef();
  const [countDownCompleted, setCountDownCompleted] = useState(false);

  useEffect(() => {
    if (!isConnected) {
      // Connection to the Ethereum provider is lost
      disconnect();
    }
  }, [address, isConnected, disconnect]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (<></>);
    } else {
      // Render a countdown
      return (
        <>
        <div className="get-ready">GET READY</div>
        <div className="show-counter-s">
          <DateTimeDisplay value={days} type={'Days'} isDanger={false} />
          <p className="dotss">:</p>
          <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
          <p className="dotss">:</p>
          <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
          <p className="dotss">:</p>
          <DateTimeDisplay value={seconds} type={'Secs'} isDanger={false} />
        </div>
        </>
      );
    }
  };

  return (
    <>
    {countDownCompleted ?
      (<></>) : (
      <>
        <div id="overlay">
        <Countdown 
          date={moment(1692972000 * 1000).add(0, "seconds").toDate().getTime()} 
          renderer={renderer} 
          onComplete={e => { setCountDownCompleted(true) }} 
        />
        </div>
      </>
      )
    }
    <Header />
    <section id="entire-staking-section" className="section section--pb0 section--first" style={{height: '100vh', overflowY: 'auto'}} ref={myRef}>
        <svg xmlns="http://www.w3.org/2000/svg" width="661" height="660" viewBox="0 0 661 660" fill="none">
          <g filter="url(#filter0_f_68_10)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M566.697 -145.875C602.751 -150.508 638.452 -140.291 671.517 -125.191C708.608 -108.253 746.452 -88.6501 767.919 -53.9827C790.632 -17.3019 804.57 28.9378 792.629 70.3962C780.991 110.805 733.932 127.18 706.549 159.094C678.978 191.228 672.412 247.743 631.409 258.298C590.155 268.918 556.614 223.02 516.77 207.949C480.936 194.395 430.678 206.088 409.311 174.288C387.469 141.783 409.974 97.9682 416.98 59.4382C421.977 31.955 434.325 8.10544 444.275 -17.9964C455.048 -46.2586 456.956 -78.4554 477.872 -100.303C501.36 -124.839 533.009 -141.545 566.697 -145.875Z" fill="#0F5734"/>
          </g>
          <defs>
          <filter id="filter0_f_68_10" x="0" y="-547" width="1197" height="1206.88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_68_10"/>
          </filter>
          </defs>
        </svg>
      <Container>
        <StakeForm />
      </Container>
    </section>
    </>
  );
}

export default App;
