import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import DateTimeDisplay from './DateTimeDisplay';
import Countdown from "react-countdown";
import moment from "moment/moment";
import { useNetwork, useAccount, useSwitchNetwork, useWalletClient } from "wagmi";
import Web3 from "web3/dist/web3.min.js";
import { ethers } from 'ethers';
import Swal from "sweetalert2";
import StakeContractABI from "../contractABI/StakeAbi.json";
import froggiesContractAbi from "../contractABI/froggiesContractAbi.json";
import frog from '../images/frog-logo.png';

const froggiesContractAddress = process.env.REACT_APP_FRGST_ADDRESS;
const contractStakingAddress = process.env.REACT_APP_FRGST_STAKING_ADDRESS;
const infuraURL = process.env.REACT_APP_INFURA_URL;

const StakeForm = () => {
  const { data: signer } = useWalletClient();
  const { address, isConnected } = useAccount();
  const [provider, setProvider] = useState("");
  const [countDownCompleted, setCountDownCompleted] = useState(false);
  const {chain, chains} = useNetwork();

  const [balance, setBalance] = useState(0);
  const [apyOption, setApyOption] = useState(0);
  const [amount, setAmount] = useState(0);
  const [approved, setApproved] = useState(false)
  const [decimals, setDecimals] = useState(18);
  const [burnOption, setBurnOption] = useState(0);
  const [stackedBal, setStackedBal] = useState(0);
  const [pending, setPending] = useState(0);
  const [stakeTime, setStakeTime] = useState(0);
  const [apy, setApy] = useState(0);
  const [period, setPeriod] = useState(0);
  const [burnRate, setBurnRate] = useState(0);
  const [rewardsSim, setRewardsSim] = useState(0);
  const [burnedSim, setBurnedSim] = useState(0);
  const [totalBurned, setTotalBurned] = useState(0);
  const [totalUsersStake, setTotalUsersStake] = useState(0);
  const [inStakingPool, setInStakingPool] = useState(0);
  const [compRefr, setCompRefr] = useState(false);


  const THREE_MONTHS = 3 * (2592000);
  const SIX_MONTHS = 6 * (2592000);
  const TWELVE_MONTHS = 12 * (2592000);

  const showAlert = (title, text, icon) => {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showConfirmButton: false,
        background: 'rgba(3, 3, 17, .95)',
        color: '#FFFFFF',
        backdrop: `rgba(3, 3, 17, .65)`,
        timer: 3500
      });
  }

  const showLoading = (title) => {
    Swal.fire({
        title: title,
        timerProgressBar: true,
        showConfirmButton: false,
        background: 'rgba(3, 3, 17, .95)',
        color: '#FFFFFF',
        backdrop: `rgba(3, 3, 17, .65)`,
        didOpen: () => {
          Swal.showLoading()
        },
      });
  }

  const handleApprove = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    if(chain.id !== 56 ){
      showAlert('Error', 'Switch to BSC network (BNB Smart Chain)', 'error');
      return;
    }
    try {
      const web3 = new Web3(provider);
      window.froggies_stake = new web3.eth.Contract(
        froggiesContractAbi, 
        froggiesContractAddress
      );
      showLoading('Approving...')
      await window.froggies_stake.methods
        .approve(contractStakingAddress, amount)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Approved!', 'success')
            setApproved(true)
            handleInput();
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Approve failed, Error: ' + e, 'error')
    }
  }

  useEffect(() => {
    /*getAllowance();*/
    getStackeInfo();
  }, [compRefr]);

  useEffect(() => {
    if (signer) {
      setProvider(signer);
    }
  }, [signer, isConnected]);
  
  useEffect(() => {
    if(signer) {
      if(chain !== undefined && chain.id !== 56 ) {
        showAlert('Error', 'Switch to BSC network (BNB Smart Chain)', 'error');
        return;
      } 
      const intervalId = setInterval(getUserInfo, 3000); // Every 3 seconds
      return () => clearInterval(intervalId);
    }
  }, [provider, signer, isConnected, compRefr]);

  /*const getAllowance = async () => {
    if(!signer || !provider) {
      return;
    }
    try {
      let web3 = new Web3(provider);
      let tokToApproveAllowance = new web3.eth.Contract(froggiesContractAbi, froggiesContractAddress);
      let allowance = await tokToApproveAllowance.methods.allowance(address, contractStakingAddress).call();
      
      // Convert allowance and amount to BigNumber using web3's utility
      let bnAllowance = web3.utils.toBN(allowance);
      let bnAmount = web3.utils.toBN(amount);

      if(bnAllowance.gte(bnAmount)){
        // Serialize big numbers for logging or other purposes
        console.log("approved", bnAllowance.toString(), bnAmount.toString());
        setApproved(true);
      }
    } catch (e) {
      // If the error is related to BigInt serialization, handle it here
      if (e instanceof TypeError && e.message.includes('BigInt')) {
         console.error('Serialization error:', e);
         showAlert('Error', 'Serialization Error', 'error');
         return;
      }
      
      console.log(e);
      showAlert('Error', 'Error: ' + e.code, 'error');
    }
  };*/

  const getUserInfo = async () => {
    if(!signer || !provider || chain.id !== 56) {
      return
    }
    let web3 = new Web3(provider);
    const tkContract = new web3.eth.Contract(froggiesContractAbi, froggiesContractAddress);
    const userBalance = await tkContract.methods.balanceOf(address).call();
    setBalance(userBalance);
    const stakeContract = new web3.eth.Contract(StakeContractABI, contractStakingAddress);
    const stakedInfo = await stakeContract.methods.stakers(address).call();
    setStackedBal(stakedInfo.amount);
    setStakeTime(stakedInfo.stakeTime);
    setBurnRate(stakedInfo.burnRate);
    let apyRate = stakedInfo.stakePeriod;
    setPeriod(apyRate);
    let actualApy = await stakeContract.methods.periodRates(apyRate).call();
    setApy(actualApy);
    try {
      let pending_rewards = await stakeContract.methods.getAccumulatedReward(address).call();
      setPending(pending_rewards)
    } catch {}
  };

  const getStackeInfo = async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(infuraURL));
    const stakeContract = new web3.eth.Contract(StakeContractABI, contractStakingAddress);
    try {
      const totalBurned = await stakeContract.methods.totalBurned().call();
      const totalUsersStaking = await stakeContract.methods.totalStaked().call();
      const inThePool = await stakeContract.methods.getRemainingStakePool().call();
      setTotalBurned(totalBurned);
      setTotalUsersStake(totalUsersStaking);
      setInStakingPool(inThePool);
    } catch(e) {
        console.log(e)
    }
  }

  const handleClaimReward = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    try {
      const web3 = new Web3(provider);
      window.froggies_stake = new web3.eth.Contract(
        StakeContractABI, 
        contractStakingAddress
      );
      showLoading('Withdrawing...')
      await window.froggies_stake.methods
        .withdrawReward()
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Withdraw rewards successful!', 'success')
            setCompRefr(!compRefr)
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Withdraw failed, Error: ' + e, 'error')
    }
  }

  const handleUnstake = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    try {
      const web3 = new Web3(provider);
      window.froggies_stake = new web3.eth.Contract(
        StakeContractABI, 
        contractStakingAddress
      );
      showLoading('Withdrawing...')
      await window.froggies_stake.methods
        .unstake()
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Withdraw successful!', 'success')
            setCompRefr(!compRefr)
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Withdraw failed, Error: ' + e, 'error')
    }
  }

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger me-3'
    },
    buttonsStyling: false
  })

  const handleEmergencyWithdraw = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    try {
      const web3 = new Web3(provider);
      window.froggies_stake = new web3.eth.Contract(
        StakeContractABI, 
        contractStakingAddress
      );
      showLoading('Withdrawing...')
      await window.froggies_stake.methods
        .emergencyUnstake()
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Emergency unstake successful!', 'success')
            setCompRefr(!compRefr)
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Withdraw failed, Error: ' + e, 'error')
    }
  }

  const handleEmergencyMessage = () => {
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this! You will lose 25% of your entire staked $FRGST!",
      icon: 'warning',
      background: 'rgba(3, 3, 17, .95)',
      color: '#FFFFFF',
      backdrop: `rgba(3, 3, 17, .65)`,
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        handleEmergencyWithdraw();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: 'Cancelled',
          text: 'Your emergency withdraw was cancelled :)',
          icon: 'error',
          background: 'rgba(3, 3, 17, .95)',
          color: '#FFFFFF',
          backdrop: `rgba(3, 3, 17, .65)`,
          showConfirmButton: false,
          timer: 3500
        });
      }
    })
  }

  const handleInputAmount = (input) => {
    if(!input.target.value || isNaN(input.target.value)) { 
      setAmount(0); 
      return 
    }

    if(chain !== undefined && chain.id !== 56) {
      showAlert('Error', 'Switch to BSC network (BNB Smart Chain)', 'error')
      return
    }
    
    let tempAmnt = ethers.parseUnits(input.target.value, decimals)
    setAmount(input.target.value > 0 ? tempAmnt : 0)

    let totalRate;
    switch(apyOption) {
      case THREE_MONTHS:
        totalRate = 5;
        break;
      case SIX_MONTHS:
        totalRate = 15;
        break;
      case TWELVE_MONTHS:
        totalRate = 40;
        break;
      default:
        totalRate = 0;
    }
    let totalRewardAllocation = input.target.value * totalRate / 100;
    let burnAmount = totalRewardAllocation * burnOption / 100;
    let totalRewards = totalRewardAllocation - burnAmount;
    setRewardsSim(totalRewards);
    setBurnedSim(burnAmount);

    console.log(amount, "amount")
  };

  const handleApyOption = (option) => {
    setApyOption(option);
    setRewardsSim(0);
    setBurnedSim(0);
    let totalRate;
    switch(option) {
      case THREE_MONTHS:
        totalRate = 5;
        break;
      case SIX_MONTHS:
        totalRate = 15;
        break;
      case TWELVE_MONTHS:
        totalRate = 40;
        break;
      default:
        totalRate = 0;
    }
    let totalRewardAllocation = Number(ethers.formatUnits(amount, decimals)) * totalRate / 100;
    let burnAmount = totalRewardAllocation * burnOption / 100;
    let totalRewards = totalRewardAllocation - burnAmount;
    setRewardsSim(totalRewards);
    setBurnedSim(burnAmount);
  }

  const handleBurnOption = (option) => {
    setBurnOption(option);
    setRewardsSim(0);
    setBurnedSim(0);
    let totalRate;
    switch(apyOption) {
      case THREE_MONTHS:
        totalRate = 5;
        break;
      case SIX_MONTHS:
        totalRate = 15;
        break;
      case TWELVE_MONTHS:
        totalRate = 40;
        break;
      default:
        totalRate = 0;
    }
    let totalRewardAllocation = Number(ethers.formatUnits(amount, decimals)) * totalRate / 100;
    let burnAmount = totalRewardAllocation * option / 100;
    let totalRewards = totalRewardAllocation - burnAmount;
    setRewardsSim(totalRewards);
    setBurnedSim(burnAmount);
  }

  const handleInput = async () => {
    if (address === "" || address === undefined || address == null || (!provider && !signer) || !isConnected) {
      showAlert('Error', 'Please connect your wallet!', 'error')
      return;
    }
    if(burnOption === 0) {
      showAlert('Error', 'Select a burn option!', 'error')
      return;
    }
    if(apyOption === 0) {
      showAlert('Error', 'Select a period option!', 'error')
      return
    }
    if(chain.id !== 56) {
      showAlert('Error', 'Switch to BSC network (BNB Smart Chain)', 'error')
      return
    }

    try {
      const web3 = new Web3(provider);
      window.froggies_stake = new web3.eth.Contract(
        StakeContractABI, 
        contractStakingAddress
      );
      showLoading('Staking...')
      await window.froggies_stake.methods
        .stake(amount, burnOption, apyOption)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'You staked!', 'success')
            setApproved(false);
            setCompRefr(!compRefr);
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
          } else {
            showAlert('Error', error, 'error')
          }
        });
    } catch(e) {
      console.log(e)
      showAlert('Error', 'Stake failed, Error: ' + e, 'error')
    }
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <>
        Period ended!
        </>
      );
    } else {
      // Render a countdown
      return (
        <>
        <div className="show-counter">
          <DateTimeDisplay value={days} type={'Days'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
        </div>
        </>
      );
    }
  };

  return (
    <Row>
      <Col className='col-12 col-lg-12 btm'>
        <div className='general-info'>
          <img src={frog} alt="Froggies Token" />
          <div className='title'>
            MONEY NEVER FELT SO <span className='green'>GREEN.</span>
          </div>
          <div className='cards'>
            <div className='card'>
              <div className='card-title'>Total Staked</div>
              <div className='card-info'>{Number(ethers.formatUnits(totalUsersStake, decimals)).toLocaleString("en-US", {   
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
              })}</div>
            </div>
            <div className='card'>
              <div className='card-title'>Staking Pool</div>
              <div className='card-info'>{Number(ethers.formatUnits(inStakingPool, decimals)).toLocaleString("en-US", {   
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
              })}</div>
            </div>
            <div className='card'>
              <div className='card-title'>Total Burned</div>
              <div className='card-info'>{Number(ethers.formatUnits(totalBurned, decimals)).toLocaleString("en-US", {   
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
              })}</div>
            </div>
          </div>
        </div>
        <div className='stake'>
          <div className='info'>
            <div className='title'>Staking</div>
            <div className='subtitle'>Don't Settle for Less - Unleash the Power <br /> of $FRGST and Earn with Our Staking Platform</div>
          </div>
          <div className='stake-card'>
            <p>After you stake, you will be able to stake again only after the period ends!</p>
            <div className='staking-period'>
              <div className='s-title'>
                Select Staking Period/APR
              </div>
              <div className='periods'>
                <button onClick={()=>handleApyOption(THREE_MONTHS)} className={apyOption === THREE_MONTHS || Number(apy) === 5 ? 'period selected' : 'period'}>3 Months / 5%</button>
                <button onClick={()=>handleApyOption(SIX_MONTHS)} className={apyOption === SIX_MONTHS || Number(apy) === 15 ? 'period selected' : 'period'}>6 Months / 15%</button>
                <button onClick={()=>handleApyOption(TWELVE_MONTHS)} className={apyOption === TWELVE_MONTHS || Number(apy) === 40 ? 'period selected' : 'period'}>12 Months / 40%</button>
              </div>
              <div className='amount-input'>
                <div className='imput-container'>
                  <label for="am-input">Input Staking Amount</label>
                  <input id='am-input' placeholder="0" onChange={(e)=>handleInputAmount(e)} />
                </div>
                <p>Balance: {Number(ethers.formatUnits(balance, decimals)).toLocaleString("en-US")} $FRGST</p>
              </div>
              <div className='s-title'>
                Select how much you want to be burned!
              </div>
              <div className='burn-options'>
                <button onClick={()=>handleBurnOption(25)} className={burnOption === 25 || Number(burnRate) === 25 ? 'burn selected' : 'burn'}>25%</button>
                <button onClick={()=>handleBurnOption(50)} className={burnOption === 50 || Number(burnRate) === 50 ? 'burn selected' : 'burn'}>50%</button>
                <button onClick={()=>handleBurnOption(75)} className={burnOption === 75 || Number(burnRate) === 75 ? 'burn selected' : 'burn'}>75%</button>
              </div>
              <div className='amount-infos'>
                <div className='imput-container'>
                  <label for="i1-input">Total Rewards</label>
                  <input disabled id='i1-input' value={Number(rewardsSim).toLocaleString("en-US")} />
                </div>
                <div className='imput-container'>
                  <label for="i2-input">Total Burn</label>
                  <input disabled id='i2-input' value={Number(burnedSim).toLocaleString("en-US")} />
                </div>
              </div>
            </div>
            {stackedBal === "0" || stackedBal === 0 ? (
              <button disabled={amount === 0 || amount === "0"} className={!signer || amount === 0 || amount === "0" ? 'stake-button disabled' : 'stake-button'} onClick={()=> approved ? handleInput() : handleApprove()}>
                {!signer ? "Connect your wallet" : signer && approved ? "Stake Now" : "Approve"}
              </button>
            ) : (
              <></>
            )}

            {signer ? (
              <>
                <div className='actions'>
                  {stackedBal === "0" || stackedBal === 0 || pending === "0" || countDownCompleted ? (<></>) : (
                    <button disabled={stackedBal === 0 || pending === 0} 
                      className={!signer || stackedBal === "0" || stackedBal === 0 || pending === 0 || pending === "0" ? 'claim disabled' : 'claim'} onClick={()=> handleClaimReward()}>
                      {!signer ? "Connect your wallet" : "Claim rewards" }
                    </button>
                  )}
                  {stackedBal === "0" || stackedBal === 0 ? (<></>) : (
                    <>
                      {countDownCompleted ? (
                        <button disabled={stackedBal === "0" || stackedBal === 0} className={!signer || stackedBal === "0" || stackedBal === 0 ? 'unstake disabled' : 'unstake'} onClick={()=> handleUnstake()}>
                          {!signer ? "Connect your wallet" : "Unstake" }
                        </button>
                      ) : (
                        <button disabled={stackedBal === "0" || stackedBal === 0} className={!signer || stackedBal === "0" || stackedBal === 0 ? 'emergency disabled' : 'emergency'} onClick={()=> handleEmergencyMessage()}>
                          {!signer ? "Connect your wallet" : "Emergency (25% fee)" }
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className='cards'>
                  <div className='card'>
                    <div className='card-title'>Amount Staked</div>
                    <div className='card-info'>{Number(ethers.formatUnits(stackedBal, 18)).toLocaleString("en-US")}</div>
                  </div>
                  <div className='card'>
                    <div className='card-title'>Claimable</div>
                    <div className='card-info'>{Number(ethers.formatUnits(pending, 18)).toLocaleString("en-US")}</div>
                  </div>
                </div>
                <div className='cards mt-0'>
                  <div className='card'>
                    <div className='card-title'>APR</div>
                    <div className='card-info'>{Number(apy).toString()}%</div>
                  </div>
                  <div className='card'>
                    <div className='card-title'>Unlock Time</div>
                    <div className='card-info'>
                    {
                      ((stakeTime !== "0" && stakeTime !== 0) && stakeTime !== null) ? (
                        <Countdown 
                          date={moment(Number(stakeTime) * 1000).add(Number(period), "seconds").toDate().getTime()} 
                          renderer={renderer} 
                          onComplete={e => { setCountDownCompleted(true) }} 
                        />
                      ) : (
                        <>--</>
                      )
                    }
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default StakeForm;
