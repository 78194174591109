import React from 'react';

const DateTimeDisplay = ({ value, type, isDanger }) => {
  // Convert the value to a string and pad with zeros if necessary
  const paddedValue = String(value).padStart(2, '0');
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p>{paddedValue}</p>
      <span>{type}</span>
    </div>
  );
};

export default DateTimeDisplay;